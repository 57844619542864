import React, { useEffect, useRef, useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { claimRewards, connectWallet, getBalance, getPrice, getPriceInfo, getReward, purchaseDigiCollect, shortAddress } from '../eth-setup/apis';





import { useTimer } from 'react-timer-hook';

function detectMob() {
  if (window.innerWidth <= 500 && window.innerHeight <= 920) return 'mobile';
  if (window.innerWidth <= 1024 && window.innerHeight <= 1244) return 'tablet';
  if (window.innerWidth <= 1440 && window.innerHeight <= 1244) return 'laptop_small';
  else return 'laptop_large';
}

// comment

const isMobile = detectMob();

// Get Guidance From Front End Experts, About this code, improvements
const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('show');
    } else {
      entry.target.classList.remove('show');
    }
  });
});

const timerDate = '30 Oct 2022 18:10:00 UTC';
const Timer1 = () => {
  const { seconds, minutes, hours, isRunning } = useTimer({
    expiryTimestamp: Date.parse(timerDate),
    onExpire: () => { },
  });

  return (
    <div className='neonText hidden hidden1' style={{ textAlign: 'center', marginTop: '20px' }}>
      {hours} hrs : {minutes} mins : {seconds} sec
    </div>
  );
};
const Timer2 = () => {
  const { seconds, minutes, hours, isRunning } = useTimer({
    expiryTimestamp: Date.parse(timerDate),
    onExpire: () => { },
  });

  return (
    <div className='neonText' style={{ textAlign: 'center', marginTop: '35px' }}>
      {hours} hrs : {minutes} mins : {seconds} sec
    </div>
  );
};

const Mint = () => {
  // const [qty, setQty] = useState(1);
  const [wallet, setWallet] = useState(null);
  const defReferrer = '0xC9149D1c8305656b2Af0701A7B498c4f90B54dE6';
  const queryParams = new URLSearchParams(window.location.search);
  const referrer = queryParams.get('referrer') || defReferrer;
  const [loader, setLoader] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const setToastText = (text) => {
    setToastMessage(text);
    setTimeout(() => {
      setToastMessage(false);
    }, 4000);
  };
  const counterRef = useRef(null);
  const [price, setPrice] = useState("");
  const [priceInfo, setPriceInfo] = useState("");
  const [balance, setBalance] = useState(0);
  const [reward, setReward] = useState(null);
  const [qty, setQty] = useState(1);

  const decrement = () => {
    setQty(qty > 1 ? qty - 1 : qty);
  };

  const increment = () => {
    setQty(qty < 10 ? qty + 1 : qty);
  };

  const debounce = (callback) => {
    setTimeout(callback, 100);
  };

  const render = (hideClassName, visibleClassName) => {
    if (counterRef.current) {
      counterRef.current.classList.add(hideClassName);

      setTimeout(() => {
        counterRef.current.innerText = qty;
        counterRef.current.classList.add(visibleClassName);
      }, 100);

      setTimeout(() => {
        counterRef.current.classList.remove(hideClassName);
        counterRef.current.classList.remove(visibleClassName);
      }, 1100);
    }
  };

  const handleInputChange = (e) => {
    const parsedValue = parseInt(e.target.value);
    if (!isNaN(parsedValue) && parsedValue >= 0) {
      setQty(parsedValue);
      render();
    }
  };

  const handleInputFocus = (e) => {
    e.currentTarget.parentNode.classList.add('is-input');
  };

  const handleInputBlur = (e) => {
    e.currentTarget.parentNode.classList.remove('is-input');
    render();
  }

  useEffect(() => {
    document.querySelectorAll('.hidden').forEach((el) => observer.observe(el));
  }, []);

  useEffect(() => {
    getPrice(setPrice);
    getPriceInfo(setPriceInfo);
    wallet && getBalance(setBalance);
    wallet && setInterval(() => {
      getReward(setReward)
    },5000)
  },[wallet])

  const set = {
    1: () => setQty(2),
    2: () => setQty(3),
    3: () => setQty(1),
  };
  const videos = ['images-mint/pnada pick another panda.mp4', 'images-mint/PAnda world tour.mp4', 'images-mint/Sea panda.mp4'];
  function getRandomIndex() {
    return Math.floor(Math.random() * videos.length);
  }
    const videoIndex = getRandomIndex();
      const [copied, setCopied] = useState(false);
      const referralLink = `${window.location.href}?referral=${wallet}`;
    
      const handleCopyClick = () => {
        if (!wallet) {
          window.alert("Please connect wallet first")
          return
        }
        if (balance === "0") {
          window.alert("Please mint NFT first")
          return
        }
        
        navigator.clipboard.writeText(referralLink);
        setCopied(true);
      };
  const center = { marginLeft: 'auto', marginRight: 'auto' };
  return isMobile === 'mobile' ? (
    <div style={{ position: 'absolute', backgroundColor: 'rgb(0, 0, 0)', height: '100vh', width: '100vw' }}>
     <Link to="/"> <img className='hidden hidden1' src='images/digi-logo.webp' alt='Digicollect' style={{ height: '55px', marginTop: '20px', ...center }} /></Link>
     
     

      {/* <Timer1 className='hidden hidden1' /> */}
      <div style={{ display: 'flex', flexDirection: 'col', height: '70x', marginTop: '5px', ...center , width:'fit-content', height:'50px'}}>
      <button className="ctrl__button ctrl__button--decrement" onClick={() => {
        debounce(() => {
          decrement();
          render('is-decrement-hide', 'is-decrement-visible');
        });
      }}>&ndash;</button>
  <div className="ctrl__counter">
    <input className="ctrl__counter-input" type="text" value="0" maxLength="10" />
    <div className="ctrl__counter-num">{qty}</div>
  </div>
  <button className="ctrl__button ctrl__button--increment" onClick={() => {debounce(() => {
          increment();
          render('is-increment-hide', 'is-increment-visible');
        });
      }}>+</button>
  </div>

      <div className='hidden hidden1' style={{ display: 'flex', flexDirection: 'col', height: '42px', marginTop: '40px', justifyContent:'center' }}>
      <Button variant="outline-warning" className='custom-btn-size2' style={{marginLeft :'5%'}} onClick={() => purchaseDigiCollect(setLoader, qty, setToastText, setWallet, setShowMessage, referrer, setBalance)}
        alt='Digicollect'
        >MINT@{price}ETH</Button>
         {/* <img
          src='images-mint/BTN MINT.png'
          onClick={() => purchaseMorphsWhitelist(setLoader, qty, setToastText, setWallet, setShowMessage)}
          // onClick={() => purchaseMorphs(setLoader, qty, setToastText, setWallet, setShowMessage)}
          alt='Digicollect'  
        />  */}
         </div>
      
      {
        <div>
          <Button variant="outline-warning" size="lg" onClick={handleCopyClick} alt='Digicollect'
        className='font1'
             style={{ marginTop: '35px' , marginLeft:'30%' }} >
        {copied ? <span><faCheck /> Copied!</span> : <span><faCopy /> Copy Referral Link</span>}
        </Button>
      </div>}
      
    <div>
      <h1 className='font1'
      style={{ marginTop: '20px', marginLeft:'30%',}} >
        { priceInfo }
      </h1>
    </div>
      <Button variant="outline-warning" size="lg" onClick={() => {
          connectWallet(setWallet);
        }}
        alt='Digicollect'
        className='hidden hidden1 font'
        style={{ height: '45px', marginTop: '30px', marginLeft:'30%',}}>{ shortAddress(wallet) ? 'CONNECTED' : '.CONNECT.'}</Button>{' '}

      {/* <img
        src={'images-mint/' + (wallet ? 'BTN CONNECTED.png' : 'BTN CONNECT.png')}
        onClick={() => {
          connectWallet(setWallet);
        }}
        alt='Digicollect'
        className='hidden hidden1'
        style={{ height: '45px', marginTop: '60px', ...center }}
      /> */}

      <center>
            <video autoPlay loop muted  inline='true' width='90%' style={{ marginTop: '35px' }}>
            <source src={videos[videoIndex]} type="video/mp4" />
              Image
            </video>
          </center>
    </div>
  ) : (
    <div style={{ position: 'absolute', backgroundColor: 'rgb(0, 0, 0)', width: '100vw' }}>
      <Row style={{ paddingTop: '60px', paddingLeft: '60px', paddingRight: '40px' }}>
        <Row className='hidden hidden1'>
        <Col lg={2} md={3}>
          <Link to="/"> <img src='images/digi-logo.webp' alt='digi-collect' style={{ height: '75px', ...center }} /></Link>
          </Col>
          <Col lg={2} md={3} style={{ display: 'flex', flexDirection: 'col', height: '70x', marginTop: '5px', ...center}}>
          <button className="ctrl__button ctrl__button--decrement" onClick={() => {
        debounce(() => {
          decrement();
          render('is-decrement-hide', 'is-decrement-visible');
        });
      }}>&ndash;</button>
  <div className="ctrl__counter">
    <input className="ctrl__counter-input" type="text" value="0" maxLength="10" />
    <div className="ctrl__counter-num">{qty}</div>
  </div>
  <button className="ctrl__button ctrl__button--increment" onClick={() => {debounce(() => {
          increment();
          render('is-increment-hide', 'is-increment-visible');
        });
      }}>+</button>
          </Col>
          <Col lg={2} md={2}>
          
          <Button variant="outline-warning" size="lg" onClick={() => {
          connectWallet(setWallet);
        }}
        alt='Digicollect'
        className='font'
                style={{ marginTop: '15px'}}>{ shortAddress(wallet) ? 'CONNECTED' : '.CONNECT.'}</Button>{' '}
          
          </Col>
          <Row>
          <div className="ctrl" style={{justifyContent:'center' }}>
          <Button variant="outline-warning" className='custom-btn-size1' onClick={() => purchaseDigiCollect(setLoader, qty, setToastText, setWallet, setShowMessage, referrer)}
        alt='Digicollect'
        >MINT@{price}ETH</Button>{' '}
  
</div>
</Row>
          <Row style={{justifyContent:'Center'}}>
            <div className='hidden hidden1'>
              <h1 className='font neon'
              style={{ marginTop: '25px', textAlign:'center'}} >
                { priceInfo }
              </h1>
            </div>
            </Row>
            {<Row>
              <div 
                style={{textAlign: 'center', marginTop:'30px'}}>
                <Button variant="outline-warning" size="lg" onClick={handleCopyClick} alt='Digicollect'
                  className='font'
                      style={{ marginTop: '30px', textAlign:'center' }} >
                  {copied ? <span><faCheck /> Copied!</span> : <span><faCopy /> Copy Referral Link</span>}
                </Button>
              </div>
            </Row>}

            <Row>
              <div 
                style={{textAlign: 'center', marginTop:'10px'}}>
                {/* <Button variant="outline-warning" size="lg" onClick={handleCopyClick} alt='Digicollect'
                  className='font'
                      style={{ marginTop: '30px', textAlign:'center' }} >
                  Stake 1 Nft
                </Button>
                <Button variant="outline-warning" size="lg" onClick={handleCopyClick} alt='Digicollect'
                  className='font'
                      style={{ marginTop: '30px', textAlign:'center' }} >
                  Unstake 2 Nft
                </Button> */}
                { wallet && reward > 0 && <Button variant="outline-warning" size="lg" onClick={() => claimRewards()} alt='Digicollect'
                  className='font' style={{ marginTop: '30px', textAlign: 'center' }} >
                  Collect {reward.toFixed(4)} DIGI
                </Button> }
              </div>
            </Row>
            
            
    
        <Row>
        {/* <Timer1 className='hidden hidden1' /> */}
        </Row>
        </Row>
        <Row className='hidden hidden1'>
        
           <center>
            <video autoPlay loop muted  inline='true' width='90%' style={{ marginTop: '35px' }}>
            <source src={videos[videoIndex]} type="video/mp4" />
            </video>
          </center>
         
        </Row>
      </Row>

    </div>
  );
};

export default Mint;